<template>
  <sun-select
    :id="id"
    :value="value"
    :multiple="multiple"
    :name="name"
    :options="items"
    :track-by="trackBy"
    :max-height="maxHeight"
    :label="label"
    :add-hex-color="addHexColor"
    :color-tag="colorTag"
    :internal-search="isInternalSearch"
    :loading="loading"
    :close-on-select="closeOnSelect"
    :required="required"
    :disabled="disabled"
    :disable-selected-options="disableSelectedOptions"
    :text-error="textError"
    :error="error"
    :placeholder="placeholder"
    :reseted="reseted"
    @blur="$emit('blur')"
    @focus="$emit('focus')"
    @change="emitChange"
    @searchChange="debounceSearch"
    @scrollPercent="getItemsOnScroll"
    @reseted="$emit('reseted')"
  >
    <template slot="option" slot-scope="arts">
      <slot name="option" v-bind="arts">{{ formatOptionText(arts.option) }}</slot>
    </template>
  </sun-select>
</template>

<script>
import debounce from 'lodash/debounce';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';

export default {
  name: 'AsterixAsyncSelect',
  components: {},
  props: {
    name: {
      type: String,
      default: () => 'select',
    },
    value: {
      type: [String, Number, Object, Array],
      default: () => null,
    },
    id: {
      type: String,
      default: () => 'select-items',
    },
    multiple: {
      type: Boolean,
      default: () => false,
    },
    trackBy: {
      type: String,
      default: () => 'id',
    },
    maxHeight: {
      type: Number,
      default: () => 150,
    },
    label: {
      type: String,
      default: () => 'name',
    },
    addHexColor: {
      type: String,
      default: () => 'orange',
    },
    colorTag: {
      type: String,
      default: () => 'gray',
    },
    service: {
      type: Function,
      required: true,
    },
    textError: {
      type: String,
      default: () => 'Select an option',
    },
    required: {
      type: Boolean,
      default: () => false,
    },
    forceNoCall: {
      type: Boolean,
      default: () => false,
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
    itemsPerPage: {
      type: Number,
      default: () => 10,
    },
    error: {
      type: Boolean,
      default: () => false,
    },
    disableSelectedOptions: {
      type: Boolean,
      default: () => false,
    },
    disabledItems: {
      type: Array,
      default: () => [],
    },
    searchKey: {
      type: String,
      default: () => '',
    },
    placeholder: {
      type: String,
      default: () => 'Select an option',
    },
    reseted: {
      type: Boolean,
      default: () => false,
    },
  },
  data: () => ({
    loading: false,
    isInternalSearch: false,
    items: [],
    page: 1,
    totalPages: null,
    query: '',
  }),
  computed: {
    closeOnSelect() {
      return !this.multiple;
    },
    disabledItemsIds() {
      return this.disabledItems.map(item => item[this.trackBy]);
    },
    filterSearchKey() {
      return this.searchKey || this.label;
    },
  },
  async created() {
    this.items = await this.getItems();
  },
  methods: {
    formatOptionText(item) {
      return item?.[this.label] || item;
    },
    async getItems(text = '', page = 1) {
      try {
        if (this.forceNoCall) return [];

        if (this.totalPages !== null && this.page > this.totalPages) {
          this.isInternalSearch = true;
          return [];
        }

        this.loading = true;
        const params = this.getParams(text, page);
        const { data, meta } = await this.service(params);
        this.totalPages = meta?.totalPages || 1;

        data.forEach(item => {
          const id = item[this.trackBy];
          if (id && this.disabledItemsIds.includes(id)) {
            item.$isDisabled = true;
          }
        });
        this.loading = false;
        return data;
      } catch (e) {
        if (!e.message.includes('canceled by user')) {
          this.loading = false;
        }
        return [];
      }
    },
    getParams(name, page) {
      const params = new QueryParamsBuilder(page === null ? this.page : page, this.itemsPerPage);
      if (name !== '') {
        params.addFilter(this.filterSearchKey, name);
      }
      return params;
    },
    emitChange({ value }) {
      this.$emit('change', { items: value });
    },
    debounceSearch: debounce(function ({ text }) {
      this.getSearchItems(text);
    }, 500),
    async getSearchItems(text) {
      const minQueryLong = 3;

      if (text.length < minQueryLong) {
        this.query = '';
        this.page = 1;
        this.totalPages = null;
        this.isInternalSearch = false;
      }

      // I user delete char reset page and get items
      if (this.query.length > text.length) {
        this.page = 1;
        this.totalPages = null;
        this.isInternalSearch = false;
      }

      // If internal search is true, it has all page.
      // If it has all page for 'abc' and user asks by 'abcd', this is internal search
      if ((text.length === 0 || text.length >= minQueryLong) && !this.isInternalSearch) {
        this.query = text;
        this.items = await this.getItems(text, 1);
      }
    },
    async getItemsOnScroll({ percent, direction }) {
      if (direction === 'down' && percent >= 50 && !this.isInternalSearch) {
        if (this.loading) return;
        this.page++;
        const data = await this.getItems(this.query, this.page);
        this.items = this.items.concat(data);
      }
    },
  },
};
</script>

<style scoped>
::v-deep .multiselect__option--highlight::after {
  height: 100%;
  display: flex;
  align-items: center;
}

.max-w-2\/4 {
  max-width: 50%;
}
</style>
