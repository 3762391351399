var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("sun-select", {
    attrs: {
      id: _vm.id,
      value: _vm.value,
      multiple: _vm.multiple,
      name: _vm.name,
      options: _vm.items,
      "track-by": _vm.trackBy,
      "max-height": _vm.maxHeight,
      label: _vm.label,
      "add-hex-color": _vm.addHexColor,
      "color-tag": _vm.colorTag,
      "internal-search": _vm.isInternalSearch,
      loading: _vm.loading,
      "close-on-select": _vm.closeOnSelect,
      required: _vm.required,
      disabled: _vm.disabled,
      "disable-selected-options": _vm.disableSelectedOptions,
      "text-error": _vm.textError,
      error: _vm.error,
      placeholder: _vm.placeholder,
      reseted: _vm.reseted,
    },
    on: {
      blur: function ($event) {
        return _vm.$emit("blur")
      },
      focus: function ($event) {
        return _vm.$emit("focus")
      },
      change: _vm.emitChange,
      searchChange: _vm.debounceSearch,
      scrollPercent: _vm.getItemsOnScroll,
      reseted: function ($event) {
        return _vm.$emit("reseted")
      },
    },
    scopedSlots: _vm._u(
      [
        {
          key: "option",
          fn: function (arts) {
            return [
              _vm._t(
                "option",
                function () {
                  return [_vm._v(_vm._s(_vm.formatOptionText(arts.option)))]
                },
                null,
                arts
              ),
            ]
          },
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }